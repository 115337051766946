import React from 'react';
import { graphql } from 'gatsby';
import { Box, Container } from '@chakra-ui/react';

import Markdown from '../components/markdown';
import Layout from '../components/layout';
import PRIVACY_POLICY from '../../PRIVACY_POLICY.md';

const PrivacyPolicyPage = () => {
  return (
    <Layout title="CreditCalculator - Privacy Policy">
      <Container maxWidth="container.xl">
        <Box marginY={{ base: '4rem', lg: '8rem' }}>
          <Markdown>{PRIVACY_POLICY}</Markdown>
        </Box>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PrivacyPolicyPage;
