import React from 'react';
import MarkdownComponent from 'markdown-to-jsx';
import {
  Box,
  Heading,
  Text,
  OrderedList,
  UnorderedList,
  ListItem,
  Link,
} from '@chakra-ui/react';

const options = {
  overrides: {
    h1: props => (
      <Heading as="h1" {...props} fontSize={{ base: 'xl', lg: '6xl' }} />
    ),
    h2: props => (
      <Heading as="h2" {...props} fontSize={{ base: 'md', lg: 'xl' }} />
    ),
    h3: props => (
      <Heading as="h3" {...props} fontSize={{ base: 'sm', lg: 'lg' }} />
    ),
    h4: props => (
      <Heading as="h4" {...props} fontSize={{ base: 'sm', lg: 'md' }} />
    ),
    h5: props => (
      <Heading as="h5" {...props} fontSize={{ base: 'sm', lg: 'md' }} />
    ),
    h6: props => (
      <Heading as="h6" {...props} fontSize={{ base: 'sm', lg: 'md' }} />
    ),
    a: props => <Link {...props} color="blue.500" />,
    p: props => (
      <Text
        as="p"
        {...props}
        fontSize={{ base: 'sm', lg: 'md' }}
        textAlign="justify"
      />
    ),
    ol: props => <OrderedList {...props} fontSize={{ base: 'sm', lg: 'md' }} />,
    ul: props => (
      <UnorderedList
        {...props}
        fontSize={{ base: 'sm', lg: 'md' }}
        spacing="3"
      />
    ),
    li: props => <ListItem {...props} />,
  },
};

const Root = {
  '& h1, & h2, & h3, & h4, & h5, & h6, & ul, & ol, & hr': {
    marginTop: { base: '3rem', lg: '4rem' },
    marginBottom: { base: '1.4rem', lg: '2.4rem' },
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  '& p': {
    marginTop: { base: '1.2rem', lg: '1.8rem' },
    '&:first-child': {
      marginTop: 0,
    },
  },
};

const Markdown = props => (
  <Box className="markdown" sx={Root}>
    <MarkdownComponent {...props} options={options} />
  </Box>
);

export default Markdown;
